//git add .
//git commit -m "first commit"
//git push -u origin main   
//npm run deploy          
//www.transformedwithincounseling.com

// "homepage": " https://thomascoenen.github.io/counseling-website.github.io/",

//https://developers.google.com/search/docs/guides/get-started

//google-site-verification=9RfSxdPtmlAcyJhm3kM-R0AlhVBXY1lxRZYIyiaTM54

import './App.css';
import { BrowserRouter, Route, Link} from 'react-router-dom';
import About from './components/About';
import Footer from './components/Footer';
import Homescreen from './components/Homescreen';
import Contact from './components/Contact';
import Nav from './components/Nav';
import FAQ from './components/FAQ';
import Rates from './components/Rates';
import Services from './components/Services';
import Head from 'next/head';

function App(props) {
  return (
    <BrowserRouter>
        <Head>
          <title>Transformed Within Counseling, LLC</title>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

      <div className="content">
        <Nav/>
        <Route path="/faq" component={FAQ}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/rates" component={Rates}></Route>
        <Route path="/services" component={Services}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/" component={Homescreen} exact></Route>
        <Footer className="Footer"/>
        <br/>
      </div>
  </BrowserRouter>

  );
}

export default App;
